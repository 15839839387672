import Api from '@/vue/api/backoffice';

import { computed } from 'vue';

export const useAuthenticatedUser = store => {
  const currentBookingType = computed(() => store.getters['BookingType/getCurrentBookingType']);
  const bookingTypeCollection = computed(() => store.getters['BookingType/getBookingTypeCollection']);
  const currentProfile = computed(() => store.getters['Profile/getCurrentProfile']);
  const profileCollection = computed(() => store.getters['Profile/getProfileCollection']);

  const setAuthenticatedUser = (userDetailsResponse = {}) => {
    const setUserData = data => store.dispatch('UserData/setUserData', data);
    const setBookingTypeCollection = type => store.dispatch('BookingType/setBookingTypeCollection', type);
    const setCurrentBookingType = type => store.dispatch('BookingType/setCurrentBookingType', type);
    const setProfileCollection = profileUserCollection => store.dispatch('Profile/setProfileCollection', profileUserCollection);
    const setCurrentProfile = currentUserProfile => store.dispatch('Profile/setCurrentProfile', currentUserProfile);
    const setCurrentTariff = currentTariff => store.dispatch('Tariff/setCurrentTariff', currentTariff);
    const setUserInsurance = userInsurance => store.dispatch('UserInsurance/setUserInsurance', userInsurance);
    const setCompanyData = companyData => store.dispatch('Company/setCompanyData', companyData);

    setUserData(userDetailsResponse);

    const {
      booking_types: bookingTypes,
      profiles,
      tariff,
      insurance,
      company,
    } = userDetailsResponse;

    setBookingTypeCollection(bookingTypes);
    const bookingType = currentBookingType.value || bookingTypeCollection.value[0];

    setCurrentBookingType(bookingType);

    setProfileCollection(profiles);
    const profile = currentProfile.value || profileCollection.value[0];
    setCurrentProfile(profile);

    setCurrentTariff(tariff);
    setUserInsurance(insurance);
    setCompanyData(company);
  };

  const fetchExtraUserData = async userDetailsResponse => {
    const fetchCSOperator = operatorUUID => store.dispatch('CSOperator/fetchCSOperator', operatorUUID);

    if (userDetailsResponse.company) {
      const dedicatedCsOperatorUuid = userDetailsResponse?.company.dedicated_fleet_cs_operator;

      if (dedicatedCsOperatorUuid) {
        fetchCSOperator(dedicatedCsOperatorUuid);
      }
    }
  };

  const fetchAuthenticatedUser = () => Api.userCompany.getUserDetail();

  return {
    setAuthenticatedUser,
    fetchExtraUserData,
    fetchAuthenticatedUser,
  };
};
