import { computed, nextTick } from 'vue';
import { useStore } from 'vuex-composition-helpers/dist';
import { NOTIFICATION_TYPE } from '@emobg/web-components';

import { NAMESPACE } from '@Shared/store/AppNotifications/AppNotificationsModule';

import { NOTIFICATION_DEFAULT_PRIORITY } from '@/components/NotificationList/constants/notification.const';

export const useNotifications = () => {
  const store = useStore();

  const currentActiveNotification = computed(() => store.getters[`${NAMESPACE}/getActiveNotification`]);

  const onActiveNotificationClose = notification => {
    store.dispatch(`${NAMESPACE}/onActiveNotificationClose`, notification);
  };

  function removeAllNotifications(force) {
    store.dispatch(`${NAMESPACE}/removeAllNotifications`, force);
  }

  const notify = (message, type, priority) => {
    nextTick(() => {
      store.dispatch(`${NAMESPACE}/addNotificationToQueue`, {
        type,
        priority,
        isClosable: true,
        ...message,
      });
    });
  };

  const notifySuccess = (message, priority = NOTIFICATION_DEFAULT_PRIORITY.success) => {
    notify(message, NOTIFICATION_TYPE.success, priority);
  };

  const notifyError = (message, priority = NOTIFICATION_DEFAULT_PRIORITY.danger) => {
    notify(message, NOTIFICATION_TYPE.danger, priority);
  };

  const notifyWarning = (message, priority = NOTIFICATION_DEFAULT_PRIORITY.warning) => {
    notify(message, NOTIFICATION_TYPE.warning, priority);
  };

  const notifyInfo = (message, priority = NOTIFICATION_DEFAULT_PRIORITY.info) => {
    notify(message, NOTIFICATION_TYPE.info, priority);
  };

  return {
    currentActiveNotification,
    onActiveNotificationClose,
    removeAllNotifications,
    notify,
    notifySuccess,
    notifyError,
    notifyWarning,
    notifyInfo,
  };
};
